:root {
  --primary-color: #3fb2bf;
  --muted-primary: #2f99a8;
}

::-webkit-scrollbar {
  width: 5px;
  margin-left: 1px;
}

::-webkit-scrollbar-thumb {
  background: #8d99a6;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #36414c;
}

.datepicker--button,
.datepicker--cell.-current- {
  color: var(--primary-color);
}

.btn-primary,
.datepicker--cell.-selected-,
.datepicker--cell.-current-.-selected- {
  background: var(--primary-color) !important;
  border: 0px !important;
}

.datepicker--cell.-selected-:hover,
.datepicker--cell.-current-.-selected-:hover,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus,
.open>.dropdown-toggle.btn-primary {
  background: var(--muted-primary) !important;
  border: 0px !important;
}

.indicator.blue::before,
.indicator-right.blue::after {
  background: var(--muted-primary);
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(47, 153, 168, 0.6);
}

.form-control:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(63, 178, 191, 0.25);
}

.parsimony_logo {
  content: url("/assets/parsimony/images/parsimony_logo.svg");
  height: 30px;
  margin-bottom: -10px;
  margin-top: -6px;
}

.vsm-link:active .parsimony_logo path {
  stroke: #fff !important;
}

ul.nav>li.navbar-divider>.divider-line {
  border-left: 2px solid #d1d8dd;
  height: 20px;
  margin: 10px 5px;
}

ul.nav>li.dropdown-navbar-user>a>span.avatar {
  margin: 0;
  padding: 0;
}

ul.nav>li.dropdown-navbar-user>a>span.avatar>span.avatar-frame {
  border-radius: 25px;
}

ul.nav>li.dropdown-navbar-user>a>span.avatar>div.standard-image {
  color: #8d99a6;
  border-radius: 25px;
  border: 1px solid #8d99a6;
}

@media (max-width: 992px) {
  .navbar-desk {
    width: 30% !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1095px) {

  .page-head>.container,
  .page-body .page-content {
    margin-left: 60px;
    margin-right: 60px;
  }
}

/* IntroJs styling */

.introjs-tooltipReferenceLayer * {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.parsimony-tour-tooltip {
  min-width: 350px !important;
  max-width: 400px !important;
  color: #36414c;
  font-size: 14px;
}

.parsimony-tour-tooltip .introjs-tooltip-header {
  padding: 20px 20px 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.parsimony-tour-tooltip .introjs-skipbutton {
  color: #8d99a6;
  cursor: pointer;
  font-weight: bold;
  line-height: 28px;
  padding: 0 2px;
}

.parsimony-tour-tooltip .introjs-skipbutton:hover,
.parsimony-tour-tooltip .introjs-skipbutton:focus {
  color: #36414c;
  outline: none;
  text-decoration: none;
}

.parsimony-tour-tooltip .introjs-tooltiptext {
  padding: 10px 20px;
}

.parsimony-tour-tooltip .introjs-tooltiptext a {
  color: var(--primary-color);
}

.parsimony-tour-tooltip .introjs-tooltipbuttons {
  border-top: 1px solid #d1d8dd;
  padding: 15px 20px 20px;
  white-space: nowrap;
}

.parsimony-tour-tooltip .introjs-button {
  padding: 5px 10px;
  background-color: #f0f4f7;
  border: 1px solid #d1d8dd;
  border-radius: 5px;
  color: #36414c;
  text-shadow: none;
}

.parsimony-tour-tooltip .introjs-button:hover,
.parsimony-tour-tooltip .introjs-button:active,
.parsimony-tour-tooltip .introjs-button:focus {
  outline: none;
  text-decoration: none;
  box-shadow: none;
  border: 1px solid #d1d8dd;
  background-color: #cfdce5;
  color: #36414c;
}

.parsimony-tour-tooltip .introjs-nextbutton {
  float: right;
  background: var(--primary-color);
  border: 1px solid #d1d8ddff;
  color: #fff;
}

.parsimony-tour-tooltip .introjs-nextbutton:hover,
.parsimony-tour-tooltip .introjs-nextbutton:active,
.parsimony-tour-tooltip .introjs-nextbutton:focus {
  background: var(--muted-primary);
  border: 1px solid #d1d8ddff;
  color: #fff;
}

.parsimony-tour-tooltip .introjs-disabled,
.parsimony-tour-tooltip .introjs-disabled:hover,
.parsimony-tour-tooltip .introjs-disabled:focus {
  color: #fff;
  background-color: #b1bdca;
  border: 1px transparent;
}

.parsimony-tour-tooltip .introjs-progress {
  margin: 10px 20px;
  border-radius: 5px;
  background-color: #e0e0e0;
}

.parsimony-tour-tooltip .introjs-progressbar {
  background-color: var(--primary-color);
}